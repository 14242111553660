"use strict"

import { AxialButtonBase } from "../../../../framework/js/button/AxialButtonBase";
import { EnvironmentSingleton } from "../../../../framework/js/core/EnvironmentSingleton";
import { AxialEffectBase } from "../../../../framework/js/effect/AxialEffectBase";

class MenuButton extends AxialButtonBase
{
    // event
    #boundFinalizeComponent;
    #boundStateChangedHandler;
    #boundPointerDownHandler;
    #boundPointerUpHandler;
    #boundEffectEndedHandler;
    #boundEffectStopedHandler;
    
    #boundPlayEffectOut;
    
    // vars
    #initWidth;
    #tipWidth;
    #tipHeight;
    // vars
    #normal;
    #text;
    #square;
    #content;
    #insideSeparator;

    #filterNum;
    #filterIn;
    #filterOut;
    #blurIn;
    #blurOut

    #min = 0;
    #max = 20;
    #duration = 500;
    #effectIn;
    #effectOut;

    #boundCorrection = 4;
    #translate = 0;
    #hasLeft = false;
    
    constructor( element )
    { 
        super( element );

        if( EnvironmentSingleton.IS_IOS == true )
        {
            this.#boundCorrection = 14;
        }

        this.#boundFinalizeComponent = this.#finalizeComponent.bind(this);
        this.#boundStateChangedHandler = this.#stateChangedHandler.bind(this);
        this.#boundPointerDownHandler = this.#pointerDownHandler.bind(this);
        this.#boundPointerUpHandler = this.#pointerUpHandler.bind(this);
        this.#boundEffectEndedHandler = this.#effectEndedHandler.bind(this);
        this.#boundEffectStopedHandler = this.#effectStopedHandler.bind(this);

        this.#boundPlayEffectOut = this.#playEffectOut.bind(this);

        this.#normal = this.element.getElementsByClassName("shp-menu-item-text")[0];
        this.#text = this.element.getElementsByClassName("shp-menu-item-tip-text")[0];
        this.#square = this.element.getElementsByClassName("shp-menu-item-tip-square")[0];
        this.#content = this.element.getElementsByClassName("shp-menu-item-tip-content")[0];
        //this.#insideSeparator = this.element.getElementsByClassName("shp-menu-separator-inside")[0];

        this.#effectIn = new AxialEffectBase();
        this.#effectOut = new AxialEffectBase();

        this.#filterNum = this.element.dataset.filter || "";
        if( this.#filterNum != "" )
        {
            this.#filterIn = "url(#itemIn" + this.#filterNum + ")";
            this.#filterOut = "url(#itemOut" + this.#filterNum + ")";

            this.#blurIn = document.getElementById("itemBlurIn" + this.#filterNum);
            this.#blurOut = document.getElementById("itemBlurOut" + this.#filterNum);

            this.#effectIn.target = this.#blurIn;
            this.#effectIn.property = "stdDeviation";
            this.#effectIn.duration = (this.#duration+40);
            this.#effectIn.addEventListener("effectended", this.#boundEffectEndedHandler);
            this.#effectIn.addEventListener("effectstoped", this.#boundEffectStopedHandler);

            this.#effectOut.target = this.#blurOut;
            this.#effectOut.property = "stdDeviation";
            this.#effectOut.duration = this.#duration;
        }
        setTimeout( this.#boundFinalizeComponent, 200);
    }

    #finalizeComponent()
    {
        let normalBounds = this.#normal.getBoundingClientRect();
        this.#initWidth = normalBounds.width;

        let bounds = this.#text.getBoundingClientRect();
        this.#tipWidth = bounds.width + this.#boundCorrection;
        this.#tipHeight = bounds.height;

        this.#translate = -this.#tipWidth + ( this.#tipWidth - this.#initWidth);
        //console.log(this.#translate);

        // filter
        this.#square.style.width = this.#tipWidth + "px";
        this.#square.style.height = this.#tipHeight + "px";
        this.#square.style.transform = "translateX(-100%)";
        this.#square.style.display = "none";

        this.#content.style.width = this.#tipWidth + "px";
        this.#content.style.height = this.#tipHeight + "px";
        this.#content.style.filter = this.#filterOut;
        

        // init
        this.element.style.width = (this.#initWidth + this.#boundCorrection) + "px";
        this.#text.style.display = "none";

        this.element.addEventListener("statechanged", this.#boundStateChangedHandler);
        this.useStates = true;

        if( this.element.dataset.card == "card" )
        {
            this.element.addEventListener("pointerdown", this.#boundPointerDownHandler);
            this.element.addEventListener("pointerup", this.#boundPointerUpHandler);
        }
    }

    #stateChangedHandler( event )
    {
        if( this.#effectIn.isRunning == true )
        {
            this.#hasLeft = true;
            return;
        }

        switch( this.currentState )
        {
            case "normal":
                this.#playEffectOut();
            break;

            case "over":
                this.#playEffectIn();
            break;

            default:
                this.#playEffectOut();
            break;
        }
    }

    #playEffectIn()
    {
        if( this.#filterNum != "" )
        {
            this.element.style.width = (this.#boundCorrection + this.#tipWidth) + "px";
            let newTransform = "translateX(" + this.#translate +  "px)";
            this.#square.style.transform = newTransform;
            //this.#insideSeparator.style.opacity = "0";

            this.#normal.style.filter = this.#filterIn;

            this.#effectIn.initialPropertyValue = this.#min;
            this.#effectIn.destination = this.#max;
            this.#effectIn.start();

            this.#square.style.display = "block";
            this.#content.style.filter = this.#filterOut;

            this.#effectOut.initialPropertyValue = this.#max;
            this.#effectOut.destination = this.#min;
            this.#effectOut.start();
        }
    }

    #playEffectOut()
    {
        if( this.#filterNum != "" )
        {
            console.log("effect out");
            //console.log(this.#effectIn.isRunning);

            // exclusion if roll over durint the out to prevent out effect played both
            if( this.#normal.style.filter == "none" )
            {
                return;
            }

            this.element.style.width = (this.#initWidth + this.#boundCorrection) + "px";
            this.#square.style.transform = "translateX(-100%)";
            //this.#insideSeparator.style.opacity = "1";

            this.#normal.style.filter = this.#filterIn;

            this.#effectIn.initialPropertyValue = this.#max;
            this.#effectIn.destination = this.#min;
            this.#effectIn.start();

            this.#content.style.filter = this.#filterOut;

            this.#effectOut.initialPropertyValue = this.#min;
            this.#effectOut.destination = this.#max;
            this.#effectOut.start();
        }
    }

    #effectEndedHandler( event )
    {
        //console.log("effect menu ended");
        let deviation = Number( this.#blurIn.getAttribute("stdDeviation") );
        //console.log("deviation = " + deviation);
        //console.log("this.#hasLeft = " + this.#hasLeft);

        if( deviation >= this.#max )
        {
            if( this.#hasLeft == true )
            {
                this.#hasLeft = false;
                setTimeout(this.#boundPlayEffectOut, 50);
            }
            else
            {
                this.#content.style.filter = "none";
            }
            
        }
        else
        {
            this.#normal.style.filter = "none";
            this.#square.style.display = "none";
        }
    }

    #effectStopedHandler( event )
    {
        this.#playEffectOut();
    }

    #pointerDownHandler( event )
    {
        this.element.setPointerCapture(event.pointerId)
        let card = document.getElementById("card");
        card.style.transform = "translateX(0%)";
    }

    #pointerUpHandler( event )
    {
        document.getElementById("card").style.transform = "translateX(101%)";
    }
}

export { MenuButton }