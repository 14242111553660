"use strict"

import { AxialComponentBase } from "../application/AxialComponentBase";

class AxialButtonBase extends AxialComponentBase
{
    constructor( element )
    {
        super(element);
    }

    _statePointerEnterHandler( event )
    {
        this.currentState = "over";
    }

    _statePointerLeaveHandler( event )
    {
        this.currentState = "normal";
    }

    _init()
    {
        /*
        if( this.element instanceof HTMLDivElement == false )
        {
            throw new TypeError("HTMLDivElement value expected");
        }
        */
        super._init();

        if( this.element.classList.contains("ax-button-base") == false )
        {
            this.element.classList.add("ax-button-base");
        }

        this.addState("normal");
        this.addState("over");
    }
}

export { AxialButtonBase }