"use strict"

const ENVIRONMENT_BROWSERS = new Set(["chrome", "firefox", "edge", "explorer", "opera", "other", "safari"]);
const ENVIRONMENT_MANIMULATION_EVENT_TYPES = new Set(["mouse", "touch", "pointer"]);

var ENVIRONMENT_PRIVILEGED_MANIPULATION_EVENT_TYPE = undefined;
var ENVIRONMENT_IS_IOS = undefined;
var ENVIRONMENT_IS_MOBILE = undefined;
var ENVIRONMENT_IS_DESKTOP = undefined;
var ENVIRONMENT_IS_TABLET = undefined;
var ENVIRONMENT_IS_PHONE = undefined;

class Environment
{
    static #BROWSER = undefined;
    static #IS_IOS = undefined;
    static #IS_MOBILE = undefined;
    static #IS_DESKTOP = undefined;
    static #IS_TABLET = undefined;
    static #IS_PHONE = undefined;
    static #PRIVILEGED_MANIPULATION_EVENT_TYPE = undefined;

    /**
     * Return the current browser
     * @type { String }
     */
    static get browser()
    {
        if( Environment.#BROWSER != undefined ) { return Environment.#BROWSER; }
        let browser = "other";
        let ua = navigator.userAgent.toLowerCase();
        if( ua.indexOf("edge") > 0 )            { browser = "edge"; }
        if( ua.indexOf("firefox") > 0 )            { browser = "firefox"; }
        else if( ua.indexOf("msie") > 0 )       { browser = "explorer"; } // internet explorer <= 10
        else if( ua.indexOf(" opr/") > 0 )      { browser = "opera"; }
        else if( ua.indexOf("chrome") > 0 )     { browser = "chrome"; }
        else if( ua.indexOf("safari") > 0 )     { browser = "safari"; }
        else if( ua.indexOf("windows") > 0 && ua.indexOf("trident") > ua.indexOf("windows") )   { browser = "explorer"; } // internet explorer 11
        else { browser = "other"; }
        Environment.#BROWSER = browser
        return Environment.#BROWSER;
    }

    /**
     * Return true if we are on a phone or tablet. Return false if we are on desktop.
     * @type { Boolean }
     */
    static get isMobile()
    {
        if( Environment.#IS_MOBILE != undefined ) { return Environment.#IS_MOBILE; }
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) )
        {
            Environment.#IS_MOBILE = true;
            Environment.#IS_DESKTOP = false;
        }
        else
        {
            Environment.#IS_MOBILE = false;
            Environment.#IS_DESKTOP = true;
        }
        return Environment.#IS_MOBILE;
    }

    /**
     * Return true if we are on a tablet. Return false if we are on desktop
     * @type { Boolean }
     */
    static get isTablet()
    {
        if( Environment.#IS_TABLET != undefined ) { return Environment.#IS_TABLET; }
        Environment.#IS_TABLET = false;
        if( navigator.userAgent.match(/iPad/i) )
        {
            Environment.#IS_TABLET = true;
        }
        else if( navigator.userAgent.match(/Android/i) )
        {
            /*
            if ( ( (screen.width  >= 480) && (screen.height >= 800) ) || ( (screen.width  >= 800) && (screen.height >= 480) ) )
            {
                ENVIRONMENT_IS_TABLET = true;
            }
            */
           Environment.#IS_TABLET = false;
        }
        return Environment.#IS_TABLET;
    }

    /**
     * Return true if we are on a phone.
     * @type { Boolean }
     */
    static get isPhone()
    {
        if( Environment.#IS_PHONE != undefined ) { return Environment.#IS_PHONE; }
        Environment.#IS_PHONE = false;
        if( Environment.isMobile == true && Environment.isTablet == false )
        {
            Environment.#IS_PHONE = true;
        }
        return Environment.#IS_PHONE;
    }

    /**
     * Return true if we are on desktop. Return false if we are on a phone or tablet.
     * @type { Boolean }
     */
    static get isDesktop()
    {
        if( Environment.#IS_DESKTOP != undefined ) { return Environment.#IS_DESKTOP; }
        return !Environment.isMobile;
    }

    /**
     * Return true if we are on iOS device.
     * @type { Boolean }
     */
    static get isIOS()
    {
        if( Environment.#IS_IOS != undefined ) { Environment.#IS_IOS; }
        if( navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i) )
        {
            Environment.#IS_IOS = true;
        }
        else
        {
            Environment.#IS_IOS = false;
        }
        return Environment.#IS_IOS;
    }

    /**
     * Return the best event type for the current device. Value returned is 'mouse', 'touch' or 'pointer'.
     * @type { String }
     */
    static get privilegedManipulationEventType()
    {
        if( Environment.#PRIVILEGED_MANIPULATION_EVENT_TYPE != undefined ) { return Environment.#PRIVILEGED_MANIPULATION_EVENT_TYPE; }
        
        if( window.PointerEvent != undefined )
        {
            Environment.#PRIVILEGED_MANIPULATION_EVENT_TYPE = "pointer";
        }
        else
        {
            if( Environment.isIOS == true ) 
            {
                Environment.#PRIVILEGED_MANIPULATION_EVENT_TYPE = "touch";
            }
            else
            {
                Environment.#PRIVILEGED_MANIPULATION_EVENT_TYPE = "mouse";
            }
        }
        return Environment.#PRIVILEGED_MANIPULATION_EVENT_TYPE;
        /*
        if( Environment.isIOS == true )
        {
            ENVIRONMENT_PRIVILEGED_MANIPULATION_EVENT_TYPE = "touch";
        }
        else
        {
            if( window.PointerEvent != undefined ) // better to work with pointers
            {
                ENVIRONMENT_PRIVILEGED_MANIPULATION_EVENT_TYPE = "pointer";
            }
            else
            {
                ENVIRONMENT_PRIVILEGED_MANIPULATION_EVENT_TYPE = "mouse";
            }
        }
        return ENVIRONMENT_PRIVILEGED_MANIPULATION_EVENT_TYPE;
        */
    }
}

export { Environment }