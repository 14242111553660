"use strict"

class EnvironmentSingleton
{
    static #instance = undefined;

    static #IS_DESKTOP = undefined;
    static #IS_MOBILE = undefined;

    static #IS_PHONE = undefined;
    static #IS_TABLET = undefined;

    static #IS_IOS = undefined;
    static #IS_ANDROID = undefined;

    constructor()
    {
        if( EnvironmentSingleton.#instance == undefined )
        {
            let ua = navigator.userAgent;

            // desktop / mobile
            if( ua.indexOf("Mobi") >= 0 )
            {
                EnvironmentSingleton.#IS_DESKTOP = false;
                EnvironmentSingleton.#IS_MOBILE = true;
                EnvironmentSingleton.#IS_PHONE = true;
            }
            else
            {
                EnvironmentSingleton.#IS_DESKTOP = true;
                EnvironmentSingleton.#IS_MOBILE = false;
                EnvironmentSingleton.#IS_PHONE = false;
            }

            // android
            if( ua.indexOf("Android") >= 0 )
            {
                EnvironmentSingleton.#IS_ANDROID = true;
            }
            else
            {
                EnvironmentSingleton.#IS_ANDROID = false;
            }

            // ios
            if( ua.indexOf("iPhone") >= 0 || ua.indexOf("iPad") >= 0 || ua.indexOf("iPod") >= 0 )
            {
                EnvironmentSingleton.#IS_IOS = true;
            }
            else
            {
                EnvironmentSingleton.#IS_IOS = false;
            }
            
            // tablet (special ipad case - don't care of android tablet right now)
            if( ua.indexOf("iPad") >= 0 || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) )
            {
                EnvironmentSingleton.#IS_TABLET = true;
                EnvironmentSingleton.#IS_PHONE = false;
                EnvironmentSingleton.#IS_IOS = true;
                EnvironmentSingleton.#IS_MOBILE = true;
                EnvironmentSingleton.#IS_DESKTOP = false;
            }
            else
            {
                EnvironmentSingleton.#IS_TABLET = false;
            }
            
            EnvironmentSingleton.#instance = this;
        }
    }

    static get IS_DESKTOP() { return EnvironmentSingleton.#IS_DESKTOP; }
    static get IS_MOBILE() { return EnvironmentSingleton.#IS_MOBILE; }
    static get IS_PHONE() { return EnvironmentSingleton.#IS_PHONE; }
    static get IS_TABLET() { return EnvironmentSingleton.#IS_TABLET; }
    static get IS_IOS() { return EnvironmentSingleton.#IS_IOS; }
    static get IS_ANDROID() { return EnvironmentSingleton.#IS_ANDROID; }
}

export { EnvironmentSingleton }