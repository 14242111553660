"use strict"

import { AxialMenuBase } from "./AxialMenuBase";

const AXIAL_MENU_LAYER = document.getElementById("menuLayer");

const AXIAL_MENU_DEFINITIONS = new Array();
//const AXIAL_MENU_DEFINITIONS = new Set();

class AxialMenuManager
{
    static get layer()
    {
        return AXIAL_MENU_LAYER;
    }

    static add( menu )
    {
        if( menu instanceof AxialMenuBase == false )
        {
            throw new TypeError( "AxialMenuBase value expected" );
        }
        AXIAL_MENU_DEFINITIONS.push(menu);
        // for Set Class definition
        /*
        if( AXIAL_MENU_DEFINITIONS.has( menu ) == false )
        {
            AXIAL_MENU_DEFINITIONS.add(menu);
        }
        */
    }

    static menuShown( event )
    {
        let isMenu = false;
        let classes = event.target.classList;
        let l = classes.length;
        if( l > 0 )
        {
            for( let i = 0; i < l; i++ )
            {
                let css = classes[i];
                if( css.indexOf("ax-menu-") == 0 )
                {
                    isMenu = true;
                    break;
                }
            }
            // for Set Class definition
            /*
            for( let css of classes )
            {
                if( css.indexOf("ax-menu-") == 0 )
                {
                    isMenu = true;
                    break;
                }
            }
            */
            if( isMenu == false )
            {
                AxialMenuManager.menuHidden(); // see if we pass the event
            }
        }
        else
        {
            AxialMenuManager.menuHidden(); // see if we pass the event
        }
    }

    static menuHidden()
    {
        let menuLength = AXIAL_MENU_DEFINITIONS.length;
        for( let m = 0; m < menuLength; m++ )
        {
            let menu = AXIAL_MENU_DEFINITIONS[m];
            menu.hide();
        }
        // IMPORTANT for of loop causes a bug in IE 11
        // the code below is for a Set Class, we temporary use an Array
        /*
        for( let menu of AXIAL_MENU_DEFINITIONS )
        {
            menu.hide();
        }
        */
    }
}

export { AxialMenuManager }