"use strict"

import { AxialMenuItemBase } from "./AxialMenuItemBase";
import { AxialMenuItemSeparator } from "./AxialMenuItemSeparator";
import { AxialMenuManager } from "./AxialMenuManager";

const AXIAL_MENU_HORIZONTAL_ALIGN = new Set(["left", "center", "right"]);
const AXIAL_MENU_VERTICAL_ALIGN = new Set(["bottom", "center", "right"]);

class AxialMenuBase
{

    constructor()
    {
        this._isShown = false; // completely private

        this._items = new Array(); // create a typed vector class ? // UNUSED

        this._isContextMenu = false;

        this._offset = 1; // UNUSED (for positionning)
        this._horizontalAlign = "left";
        this._verticalAlign = "bottom";

        this._element = document.createElement("div");
        this._element.classList.add("ax-menu-element");

        // the list itself !
        this._listHolder = document.createElement("ul");
        this._element.appendChild(this._listHolder);

        AxialMenuManager.layer.appendChild(this._element);
        AxialMenuManager.add( this );
    }

    get isShown() { return this._isShown; }

    get isContextMenu() { return this._isContextMenu; }
    set isContextMenu( value )
    {
        if( typeof value !== "boolean" )
        {
            throw new TypeError("Boolean value expected");
        }
        if( value == this._isContextMenu ) { return; }
        this._isContextMenu = value;
    }

    get horizontalAlign() { return this._horizontalAlign; }
    set horizontalAlign( value )
    {
        if( typeof value !== "string" )
        {
            throw new TypeError("String value expected : 'left', 'center' or 'right'");
        }
        if( AXIAL_MENU_HORIZONTAL_ALIGN.has(value) == false )
        {
            throw new TypeError("Bad string value : 'left', 'center' or 'right'");
        }
        if( value == this._horizontalAlign ) { return; }
        this._horizontalAlign = value;
    }

    get verticalAlign() { return this._verticalAlign; }
    set verticalAlign( value )
    {
        if( typeof value !== "string" )
        {
            throw new TypeError("String value expected : 'top', 'center' or 'bottom'");
        }
        if( AXIAL_MENU_VERTICAL_ALIGN.has(value) == false )
        {
            throw new Error("Bad string value : 'top', 'center' or 'bottom'");
        }
        if( value == this._verticalAlign ) { return; }
        this._verticalAlign = value;
    }

    addItem( menuItem )
    {
        if( menuItem instanceof AxialMenuItemBase == false && menuItem instanceof AxialMenuItemSeparator == false )
        {
            throw new TypeError("not an AxialMenuItemBase or an AxialMenuItemSeparator");
        }
        if( this._listHolder )
        {
            this._listHolder.appendChild( menuItem.getItem() );
        }
    }

    show( event )
    {
        event.preventDefault();
        // remove the document listener to ensure only once is added
        document.removeEventListener("click", AxialMenuManager.menuShown, false);
        // so we add th menu shown handler
        document.addEventListener("click", AxialMenuManager.menuShown, false);

        if( this._isContextMenu == true )
        {
            let contextMenuLeft = event.clientX;
            let contextMenuTop = event.clientY;

            this._element.style.left = contextMenuLeft + "px";
            this._element.style.top = contextMenuTop + "px";
            this._element.style.visibility = "visible";
        }
        else
        {
            // TODO
        }
        this._isShown = true;
    }

    hide()
    {
        document.removeEventListener("click", AxialMenuManager.menuShown, false);
        this._isShown = false;
        this._element.style.visibility = "hidden";
    }
}

export { AxialMenuBase }