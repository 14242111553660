"use strict"

import { AxialComponentBase } from "../../../../framework/js/application/AxialComponentBase";

class Particle extends AxialComponentBase
{
    #animationName = "sh-particle_animation";

    #defaultMinSize = 1;
    #defaultMaxSize = 1000;
    #minSize = 1;
    #maxSize = 3;
    #size = 1;

    #defaultMinOpacity = 0.05;
    #defaultMaxOpacity = 1;
    #minOpacity = 0.05;
    #maxOpacity = 0.5;
    #opacity = 0.23;

    #defaultMinDuration = 1;
    #defaultMaxDuration = 300;
    #minDuration = 30;
    #maxDuration = 60;
    #duration = 30;

    #color = "#000";
    #shadow = true;

    constructor( element )
    { 
        super( element );
        this.element.classList.add("sh-particle");

        // size (and positionning)
        this.#size = ( Math.random() * (this.#maxSize - this.#minSize) ) + this.#minSize;
        let px = Math.random() * 100;
        let py = Math.random() * 200;

        this.element.style.left = String(px) + "%";
        this.element.style.top = String(py) + "%";
        this.element.style.width = String(this.#size) + "px";
        this.element.style.height = String(this.#size) + "px";

        // opacity
        let o = ( Math.random() * (this.#maxOpacity - this.#minOpacity) ) + this.#minOpacity;
        this.element.style.opacity = String(o);

        // color
        this.element.style.backgroundColor = this.#color;

        // shadow
        let shadow = "";
        if( this.#shadow == true ) { let shadow = "0px 0px 2px " + this.#color; }
        this.element.style.boxShadow = shadow;
        
        // duration
        this.#duration = ( Math.random() * (this.#maxDuration - this.#minDuration) ) + this.#minDuration;
        this.element.style.animationDuration = String(this.#duration) + "s";
        this.element.style.animationName = this.#animationName;
    }

    get size() { return this.#size; }
    get opacity() { return this.#opacity; }
    get duration() { return this.#duration; }

    get minSize() { return this.#minSize; }
    set minSize( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        if( value == this.#minSize ) { return; }
        if(value < this.#defaultMinSize ) { value = this.#defaultMinSize; }
        this.#minSize = value;
        this.#drawSize();
    }

    get maxSize() { return this.#maxSize; }
    set maxSize( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        if( value == this.#maxSize ) { return; }
        if( value > this.#defaultMaxSize ) { value = this.#defaultMaxSize; }
        this.#maxSize = value;
        this.#drawSize();
    }

    #drawSize()
    {
        this.#size = ( Math.random() * (this.#maxSize - this.#minSize) ) + this.#minSize;
        this.element.style.width = String(this.#size) + "px";
        this.element.style.height = String(this.#size) + "px";
    }

    get minOpacity() { return this.#minOpacity; }
    set minOpacity( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        if( value == this.#minOpacity ) { return; }
        if(value < this.#defaultMinOpacity ) { value = this.#defaultMinOpacity; }
        this.#minOpacity = value;
        this.#drawOpacity();
    }

    get maxOpacity() { return this.#maxOpacity; }
    set maxOpacity( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        if( value == this.#maxOpacity ) { return; }
        if( value > this.#defaultMaxOpacity ) { value = this.#defaultMaxOpacity; }
        this.#maxOpacity = value;
        this.#drawOpacity();
    }

    #drawOpacity()
    {
        let o = ( Math.random() * (this.#maxOpacity - this.#minOpacity) ) + this.#minOpacity;
        this.element.style.opacity = String(o);
    }

    get color() { return this.#color; }
    set color( value )
    {
        if( typeof value !== "string" )
        {
            throw new TypeError("String value expected");
        }
        if( value == this.#color ) { return; }
        this.#color = value;
        this.#drawColor();
    }

    #drawColor()
    {
        this.element.style.backgroundColor = this.#color;
    }

    get shadow() { return this.#shadow; }
    set shadow( value )
    {
        if( typeof value !== "boolean" )
        {
            throw new TypeError("Boolean value expected");
        }
        if( value == this.#shadow ) { return; }
        this.#shadow = value;
        this.#drawShadow();
    }

    #drawShadow()
    {
        let shadow = "";
        if( this.#shadow == true ) { shadow = "0px 0px 2px " + this.#color; }
        this.element.style.boxShadow = shadow;
    }

    get minDuration() { return this.#minDuration; }
    set minDuration( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        if( value == this.#minDuration ) { return; }
        if(value < this.#defaultMinDuration ) { value = this.#defaultMinDuration; }
        this.#minDuration = value;
        this.#drawDuration();
    }

    get maxDuration() { return this.#maxDuration; }
    set maxDuration( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        if( value == this.#maxDuration ) { return; }
        if( value > this.#defaultMaxDuration ) { value = this.#defaultMaxDuration; }
        this.#maxDuration = value;
        this.#drawDuration();
    }

    #drawDuration()
    {
        this.#duration = ( Math.random() * (this.#maxDuration - this.#minDuration) ) + this.#minDuration;
        this.element.style.animationDuration = String(this.#duration) + "s";
    }
}

export { Particle }