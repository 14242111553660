"use strict";

/// frameworks imports
import { AxialComponentBase } from "../../../../framework/js/application/AxialComponentBase";
import { DomUtils } from "../../../../framework/js/utils/DomUtils";

/// sektor imports
import { Particle } from "./Particle";

class ParticlesBackground extends AxialComponentBase
{
    #numberOfParticles = 100;

    #minSize = 1;
    #maxSize = 20;
    #minOpacity = 0.05;
    #maxOpacity = 1;
    #color = "#000";

    constructor( element )
    { 
        super( element );
        this.element.classList.add("sh-bg-particles");
    }

    get numberOfParticles() { return this.#numberOfParticles; }
    set numberOfParticles( value )
    {
        if( isNaN(value) == true ) { throw new Error("Number value expected"); }
        if( value == this.#numberOfParticles ) { return; }
        this.#numberOfParticles = value;
    }

    get minSize() { return this.#minSize; }
    set minSize( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        this.#minSize = value;
    }

    get maxSize() { return this.#maxSize; }
    set maxSize( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        this.#maxSize = value;
    }

    get color() { return this.#color; }
    set color( value )
    {
        if( typeof value !== "string" )
        {
            throw new TypeError("String value expected");
        }
        if( value == this.#color ) { return; }
        this.#color = value;
    }

    get minOpacity() { return this.#minOpacity; }
    set minOpacity( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        this.#minOpacity = value;
    }

    get maxOpacity() { return this.#maxOpacity; }
    set maxOpacity( value )
    {
        if( isNaN(value) == true )
        {
            throw new TypeError("Number value expected");
        }
        this.#maxOpacity = value;
    }

    draw()
    {
        DomUtils.cleanElement(this.element);
        for( let i = 0; i < this.#numberOfParticles; i++ )
        {
            let p = document.createElement("div");
            this.element.appendChild(p);
            let particle = new Particle(p);

            particle.minSize = this.#minSize;
            particle.maxSize = this.#maxSize;
            particle.minOpacity = this.#minOpacity;
            particle.maxOpacity = this.#maxOpacity;
            particle.color = this.#color;
        }
    }
}

export { ParticlesBackground }