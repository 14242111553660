"use strict"

import { AxialButtonBase } from "../../../../framework/js/button/AxialButtonBase";

class NetworkButton extends AxialButtonBase
{
    // event
    #boundStateChangedHandler;
    #boundInternalClickHandler;
    
    // vars
    #link = "";
    
    constructor( element )
    { 
        super( element );

        this.#boundStateChangedHandler = this.#stateChangedHandler.bind(this);
        this.element.addEventListener("statechanged", this.#boundStateChangedHandler);
        this.useStates = true;

        this.#boundInternalClickHandler = this.#internalClickHandler.bind(this);
        this.element.addEventListener("click", this.#boundInternalClickHandler);

        this.#finalizeComponent();
    }

    get link() { return this.#link; }
    set link( value )
    {
        if( typeof value !== "string" )
        {
            throw new TypeError("String value expected");
        }
        if( value == this.#link ) { return; }
        this.#link = value;
    }

    #finalizeComponent()
    {
        // we will see if required
    }

    #stateChangedHandler( event )
    {
        switch( this.currentState )
        {
            case "normal":
                this.element.style.transform = "scale(1)";
            break;

            case "over":
                this.element.style.transform = "scale(1.1)";
            break;

            default:
                this.element.style.transform = "scale(1)";
            break;
        }
    }

    #internalClickHandler( event )
    {
        if( this.#link != "" )
        {
            window.open(this.#link, "_blank");
        }
    }
}

export { NetworkButton }