"use strict"

import { AxialMenuManager } from "./AxialMenuManager";

class AxialMenuItemBase
{
    constructor( label = "undefined", icon = "" )
    {
        this._label = label;
        this._icon = icon;

        this._command;
        this._boundCommandHandler;

        this._element = document.createElement("li");
        this._element.classList.add("ax-menu-item-element");

        this._iconHolder = document.createElement("i");
        this._iconHolder.classList.add("material-icons");
        this._iconHolder.classList.add("md-18");
        this._iconHolder.classList.add("ax-menu-item-icon");
        if( this._icon != "" ) // TODO : do better control here -> type / if is in set
        {
            this._iconHolder.classList.add("ax-menu-item-icon-space");
            this._iconHolder.innerHTML = this._icon;
        }
        this._iconHolder.innerHTML = this._icon;
        this._element.appendChild(this._iconHolder);

        this._labelHolder = document.createElement("span");
        this._labelHolder.classList.add("ax-menu-item-label");
        this._labelHolder.innerHTML = this._label;
        this._element.appendChild(this._labelHolder);

        this._init();
    }

    get icon() { return this._icon; }
    set icon( value )
    {
        if( typeof value !== "string" )
        {
            throw new Error("String value expected");
        }
        this._icon = value;
        if( this._icon == "" && this._iconHolder.classList.contains("ax-menu-item-icon-space") == true )
        {
            this._iconHolder.classList.remove("ax-menu-item-icon-space");
        }
        if( this._icon != "" && this._iconHolder.classList.contains("ax-menu-item-icon-space") == false )
        {
            this._iconHolder.classList.add("ax-menu-item-icon-space");
        }
        this._iconHolder.innerHTML = value;
    }

    set command( value )
    {
        if( typeof value !== "function" )
        {
            throw new Error("Function value expected");
        }
        this._command = value;
    }

    getItem() { if( this._element ) { return this._element; } }

    // we probably make it public to extend menu item to toggle menu item
    _commandHandler( event )
    {
        AxialMenuManager.menuHidden();
        if( this._command )
        {
            this._command();
        }
    }

    _init()
    {
        this._boundCommandHandler = this._commandHandler.bind(this);
        this._element.addEventListener("click", this._boundCommandHandler, false);
    }
}

export { AxialMenuItemBase }