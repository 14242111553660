"use strict"

/// framework imports
import { AxialApplicationBase } from "../../../framework/js/application/AxialApplicationBase";

/// sektor imports
import { ParticlesBackground } from "./backgrounds/ParticlesBackground";
import { NetworkButton } from "./comps/NetworkButton";
import { MenuButton } from "./comps/MenuButton";
import { TitleAnimation } from "./comps/TitleAnimation";
import { EnvironmentSingleton } from "../../../framework/js/core/EnvironmentSingleton";

import { Environment } from "../../../framework/js/core/Environment";


class SektorHeimatPresentation extends AxialApplicationBase
{
    /// vars
    // lang
    #lang;

    /// ui
    // particles
    #particlesBack;
    #particlesFront;
    // social
    #instagram;
    #facebook;
    #mail;
    // title animation
    #titleAnimation;

    #creditButton;
    #kulturButton;

    #boundCreditButtonClickHandler;
    #boundKulturButtonClickHandler;

    constructor()
    {
        super();

        console.log(Environment.browser);

        let tempLang = window.navigator.language.toLowerCase();
        if(tempLang.indexOf("fr") == 0 )
        {
            this.#lang = "fr";
        }
        else if(tempLang.indexOf("de") == 0 )
        {
            this.#lang = "de";
        }

        this.#boundCreditButtonClickHandler = this.#creditButtonClickHandler.bind(this);
        this.#boundKulturButtonClickHandler = this.#kulturButtonClickHandler.bind(this);
    }

    get lang() { return this.#lang; }

    _parseData() { this.data = JSON.parse(this.data); }

    _buildApplication()
    {
        /// ui
        // particles
        this.#particlesBack = new ParticlesBackground( document.getElementById("particlesBack") );
        this.#particlesBack.numberOfParticles = 160;
        this.#particlesBack.minSize = 2;
        this.#particlesBack.maxSize = 6;
        this.#particlesBack.minOpacity = 0.1;
        this.#particlesBack.maxOpacity = 0.64;
        this.#particlesBack.color = "#fff";
        this.#particlesBack.draw();

        this.#particlesFront = new ParticlesBackground( document.getElementById("particlesFront") );
        this.#particlesFront.numberOfParticles = 40;
        this.#particlesFront.minSize = 40;
        this.#particlesFront.maxSize = 60;
        this.#particlesFront.minOpacity = 0.023;
        this.#particlesFront.maxOpacity = 0.43;
        this.#particlesFront.color = "#fff";
        this.#particlesFront.draw();

        this.#creditButton = document.getElementById("creditButton");
        this.#creditButton.addEventListener("click", this.#boundCreditButtonClickHandler);

        this.#kulturButton = document.getElementById("kulturButton");
        //this.#kulturButton.addEventListener("click", this.#boundKulturButtonClickHandler);

        /*
        if( Environment.browser != "firefox" )
        {
            this.#particlesBack.draw();
            this.#particlesFront.draw();
        }
        */
        
        // social
        // facebook
        this.#facebook = new NetworkButton( document.getElementById("shpFacebookButton") );
        this.#facebook.link = "https://www.facebook.com/sektorheimat";

        // instagram
        this.#instagram = new NetworkButton( document.getElementById("shpInstagramButton") );
        this.#instagram.link = "https://www.instagram.com/sektorheimat";

        // mail
        this.#mail = new NetworkButton( document.getElementById("shpMailButton") );
        this.#mail.link = "mailto:info@sektorheimat.com";

        // title animation
        this.#titleAnimation = new TitleAnimation();

        let menuElements = document.getElementsByClassName("shp-menu-item");
        let menuLength = menuElements.length;
        for( let i = 0; i < menuLength; i++ )
        {
            let menuElement = menuElements[i];
            let menuButton = new MenuButton(menuElement);
        }
    }

    #creditButtonClickHandler( event )
    {
        //www.filweiss.com
        window.open("https://filweiss.com", "_blank");
    }

    #kulturButtonClickHandler( event )
    {
        window.open("mailto:kontakt@kulturgut-ost.de");
    }


}

export { SektorHeimatPresentation }