"use strict"

class AxialMenuItemSeparator
{
    constructor()
    {
        this._element = document.createElement("li");
        this._element.classList.add("ax-menu-item-separator");
    }

    getItem()
    {
        if( this._element )
        {
            return this._element;
        }
    }
}

export { AxialMenuItemSeparator }