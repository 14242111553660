"use strict"

import { AxialEffectBase } from "../../../../framework/js/effect/AxialEffectBase";

class TitleAnimation
{

    #hasSwitched = false;
    
    #sektor;
    #heimat;
    #sektorBlur;
    #heimatBlur;
    #sektorEffect;
    #heimatEffect;

    #min = 0;
    #max = 50;
    #duration = 800;
    #step = 1400;

    #timoutId = undefined;
    #boundTimeoutHandler;
    #boundEffectEndedHandler;

    constructor()
    {
        this.#boundTimeoutHandler = this.#timeoutHandler.bind(this);
        this.#boundEffectEndedHandler = this.#effectEndedHandler.bind(this);

        this.#sektor = document.getElementById("titleSektor");
        this.#heimat = document.getElementById("titleHeimat");

        this.#sektorBlur = document.getElementById("titleBlurIn");
        this.#heimatBlur = document.getElementById("titleBlurOut");

        this.#sektorEffect = new AxialEffectBase();
        this.#sektorEffect.target = this.#sektorBlur;
        this.#sektorEffect.property = "stdDeviation";
        this.#sektorEffect.duration = this.#duration;
        this.#sektorEffect.initialPropertyValue = this.#min;
        this.#sektorEffect.destination = this.#max;
        this.#sektorEffect.addEventListener("effectended", this.#boundEffectEndedHandler);
        
        this.#heimatEffect = new AxialEffectBase();
        this.#heimatEffect.target = this.#heimatBlur;
        this.#heimatEffect.property = "stdDeviation";
        this.#heimatEffect.duration = this.#duration;
        this.#heimatEffect.initialPropertyValue = this.#max;
        this.#heimatEffect.destination = this.#min;

        this.#timoutId = setTimeout(this.#boundTimeoutHandler, this.#step);
    }

    play()
    {
        this.#playAnimation();
    }

    #playAnimation()
    {
        if( this.#hasSwitched == false )
        {
            this.#sektor.style.filter = "url(#titleGooIn)";
            
            this.#sektorEffect.initialPropertyValue = this.#min;
            this.#sektorEffect.destination = this.#max;

            this.#heimatEffect.initialPropertyValue = this.#max;
            this.#heimatEffect.destination = this.#min;
        }
        else
        {
            this.#heimat.style.filter = "url(#titleGooOut)";

            this.#sektorEffect.initialPropertyValue = this.#max;
            this.#sektorEffect.destination = this.#min;

            this.#heimatEffect.initialPropertyValue = this.#min;
            this.#heimatEffect.destination = this.#max;
        }
        this.#sektorEffect.start();
        this.#heimatEffect.start();
    }

    #effectEndedHandler( event )
    {
        this.#hasSwitched = !this.#hasSwitched;
        if( this.#hasSwitched == false )
        {
            this.#sektor.style.filter = "none";
        }
        else
        {
            this.#heimat.style.filter = "none";
        }
        this.#timoutId = setTimeout(this.#boundTimeoutHandler, this.#step);
    }

    #timeoutHandler( event )
    {
        this.#playAnimation();
    }
}

export { TitleAnimation }