"use strict"

import { AxialTooltipManager } from "./AxialTooltipManager";

const AXIAL_TOOLTIP_POSITIONS = new Set(["top", "left", "right", "bottom"]);

class AxialTooltipBase
{
    constructor()
    {
        // ui element
        this._element = document.createElement("div");
        this._element.classList.add("ax-tooltip-element");

        // ui arrow
        this._arrowHolder = document.createElement("div");
        this._arrowHolder.classList.add("ax-tooltip-arrow");
        this._element.appendChild(this._arrowHolder);

        AxialTooltipManager.layer.appendChild(this._element);

        // vars
        this._target;
        this._position = "bottom";
        this._useArrow = true;
        this._offset = 1;
        
        this._color = "#fff";
        this._backgroundColor = "#000";
        this._backgroundAlpha = 1;
    }

    get target() { return this._target; }
    set target( value )
    {
        // free the tooltip !
        if( value == null || value == undefined )
        {
            this._target = value;
            return;
        }
        else if(  value instanceof HTMLElement == false ) // element == null || element == undefined ||
        {
            throw new TypeError( "HTMLElement value expected" );
        }
        if( value == this._target ) { return; }
        this._target = value;
    }

    get arrow()
    {
        if( this._arrowHolder ) { return this._arrowHolder; }
    }

    get element()
    {
        if( this._element ) { return this._element; }
    }

    get position() { return this._position; }
    set position( value )
    {
        if( typeof value !== "string" )
        {
            throw new Error("String value expected : 'top', 'left', 'right' or 'bottom'");
        }
        if( AXIAL_TOOLTIP_POSITIONS.has(value) == false )
        {
            throw new Error("Bad string value : 'top', 'left', 'right' or 'bottom' expected");
        }
        if( value == this._position ) { return };
        this._position = value;
    }

    // the script assumes (for the moment), the arrow does not change at runtime (i.e. when the tooltip is displayed)
    get useArrow() { return this._useArrow; }
    set useArrow( value )
    {
        if( typeof value !== "boolean" )
        {
            throw new Error("Boolean value expected");
        }
        if( value == this._arrow ) { return; }
        this._useArrow = value;
    }

    get offset() { return this._offset; }
    set offset( value )
    {
        if( isNaN( value ) == true )
        {
            throw new TypeError("Number value expected");
        }
        if( value == this._offset ) { return; }
        this._offset = value;
    }

    show()
    {
        AxialTooltipManager.showTooltip(this);
    }

    hide()
    {
        AxialTooltipManager.hideTooltip(this);
    }

    // utils @override
    _removeArrowPositionClass()
    {
        let arrowClasses = this._arrowHolder.classList;
        /*
        for( let css of arrowClasses )
        {
            if( css.indexOf("ax-tooltip-arrow-") == 0 )
            {
                this._arrowHolder.classList.remove(css);
            }
        }
        */

        // IMPORTANT for of loop causes a bug in IE 11
        
        let l = arrowClasses.length;
        for( let i = 0; i < l; i++ )
        {
            let css = arrowClasses[i];
            if( css.indexOf("ax-tooltip-arrow-") == 0 )
            {
                this._arrowHolder.classList.remove(css);
            }
        }
        
    }
}

export { AxialTooltipBase }